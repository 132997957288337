import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPage = ({ data, pageContext, ...props }) => {
  const { allMdx } = data
  const { pagination } = pageContext
  const { page, nextPagePath, previousPagePath } = pagination

  const posts = page
    .map(id =>
      allMdx.edges.find(
        edge =>
          edge.node.id === id && edge.node.parent.sourceInstanceName !== 'pages'
      )
    )
    .filter(post => post !== undefined)

  return (
    <Layout>
      <SEO title="Blog" />
      {posts.map(({ node: post }) => (
        <div key={post.id}>
          <h2>
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </h2>
          <p>{post.excerpt}</p>
          <Link to={post.fields.slug}>Read more...</Link>
        </div>
      ))}
      <br />
      <div>
        {previousPagePath ? (
          <Link to={previousPagePath}>Previous Page</Link>
        ) : (
          <div />
        )}
        {nextPagePath ? <Link to={nextPagePath}>Next Page</Link> : <div />}
      </div>
    </Layout>
  )
}

const Blog = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
              frontmatter: { published: { ne: false }, unlisted: { ne: true } }
              fileAbsolutePath: { regex: "//content/blog//" }
            }
          ) {
            edges {
              node {
                excerpt(pruneLength: 300)
                id
                fields {
                  title
                  slug
                  date
                }
                parent {
                  ... on File {
                    sourceInstanceName
                  }
                }
                frontmatter {
                  title
                  date(formatString: "MMMM DD, YYYY")
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => <BlogPage data={data} {...props} />}
    />
  )
}

export default Blog
